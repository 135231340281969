@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary: rgba(227, 62, 90, 1);
  --primary-5: rgba(227, 62, 90, 0.5);
  --secondary: rgba(26, 27, 47, 1);
  --white: rgba(255, 255, 255, 1);
  --gray: rgba(199, 196, 196, 1);
  --rgba-1: rgba(0, 0, 0, 0.1);
  --rgba-2: rgba(0, 0, 0, 0.2);
  --rgba-3: rgba(0, 0, 0, 0.3);
  --rgba-4: rgba(0, 0, 0, 0.4);
  --rgba-5: rgba(0, 0, 0, 0.5);
  --rgba-6: rgba(0, 0, 0, 0.6);
}

html,
body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
  color: var(--secondary);
}

body {
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #ffffff 30%), repeating-linear-gradient(rgba(0, 0, 0, 0.02), #dddddf);
  background-color: #ffffff;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}
